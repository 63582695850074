<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      max-width="800px"
    >
      <v-card>
        <v-toolbar
          color="success"
          dark
          elevation="0"
        >EDITAR PRODUCTO</v-toolbar>
        <v-card-text>
          <v-container><br>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="3"
              >
                <v-text-field
                  v-model="editedItem.barcode"
                  label="Código de barras"
                  outlined
                  dense
                  v-on:keyup.enter="focusName()"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="8"
              >
                <v-text-field

                  v-model="editedItem.name"
                  label="Nombre"
                  ref="name"
                  outlined
                  dense
                  style="text-transform:uppercase;" value=""  onkeyup="javascript:this.value=this.value.toUpperCase();"
                  v-on:keyup.enter="focusStockMaximo()"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="3"
              >

              </v-col>
            </v-row>
            <v-row>
              <SelectLab :editedItem="editedItem" :laboratories="laboratories"/>
              <v-btn fab dark small color="success" @click="dialogLaboratory = !dialogLaboratory"><v-icon dark>mdi-plus</v-icon></v-btn>
            </v-row>
            <v-row>
              <SelectGeneric :editedItem="editedItem" :generics="generics"/>
              <v-btn fab dark small color="success" @click="dialogGeneric = !dialogGeneric"><v-icon dark>mdi-plus</v-icon></v-btn>
            </v-row>
            <v-row>
              <SelectCategory :editedItem="editedItem" :categories="categories"/>
              <v-btn fab dark small color="success" @click="dialogCategory = !dialogCategory"><v-icon dark>mdi-plus</v-icon></v-btn>
            </v-row>
            <v-row>
              <SelectPresentation :editedItem="editedItem" :presentations="presentations"/>
              <v-btn fab dark small color="success" @click="dialogPresentation = !dialogPresentation"><v-icon dark>mdi-plus</v-icon></v-btn>
            </v-row>
            <v-row>
              <SelectLocation :editedItem="editedItem" :locations="locations"/>
              <v-btn fab dark small color="success" @click="dialogLocation = !dialogLocation"><v-icon dark>mdi-plus</v-icon></v-btn>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.maximum_stock"
                  label="Stock Máximo"
                  ref="aximum_stock"
                  outlined
                  dense
                  v-on:keyup.enter="focusStockMinimo()"
                  :error-messages="errors.get('maximum_stock')"
                  @keydown="errors.clear('maximum_stock')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.minimum_stock"
                  label="Stock Mínimo"
                  outlined
                  dense
                  ref="inimum_stock"
                  v-on:keyup.enter="focusStockMinimo()"
                  :error-messages="errors.get('minimum_stock')"
                  @keydown="errors.clear('minimum_stock')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="4"
              >
                <v-text-field
                    v-model="editedItem.box_quantity"
                    label="Cantidad X Caja"
                    ref="cantidadCaja"
                    outlined
                    dense
                    v-on:keyup.enter="focusCantidadBlister()"
                    :error-messages="errors.get('box_quantity')"
                    @keydown="errors.clear('box_quantity')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="4"
              >
                <v-text-field
                    v-model="editedItem.blister_quantity"
                    label="Cantidad X Blister"
                    ref="cantidadBlister"
                    outlined
                    dense
                    v-on:keyup.enter="focusCantidadPresentacion()"
                    :error-messages="errors.get('blister_quantity')"
                    @keydown="errors.clear('blister_quantity')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.presentation_sale"
                  label="Venta X Presentación"
                  ref="cantidadPresentacion"
                  outlined
                  dense
                  v-on:keyup.enter="focusPrecioCaja()"
                  :error-messages="errors.get('presentation_sale')"
                  @keydown="errors.clear('presentation_sale')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="4"
              >
                <v-text-field
                    v-model="editedItem.buy_unit"
                    label="PC. Unidad"
                    outlined
                    dense
                    @keypress.enter="changeBuyUnit()"
                    :error-messages="errors.get('buy_unit')"
                    @keydown="errors.clear('buy_unit')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="4"
              >
                <v-text-field
                    v-model="editedItem.buy_blister"
                    label="PC. Blister"
                    outlined
                    dense
                    @keypress.enter="changeBuyBlister()"
                    :error-messages="errors.get('buy_blister')"
                    @keydown="errors.clear('buy_blister')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="4"
              >
                <v-text-field
                    v-model="editedItem.buy_box"
                    label="PC. Caja"
                    outlined
                    dense
                    v-on:keyup.enter="focusPrecioVentaUnidad()"
                    @keypress.enter="changeBuyBox()"
                    :error-messages="errors.get('buy_box')"
                    @keydown="errors.clear('buy_box')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="4"
              >
                <v-text-field
                    v-model="editedItem.sale_unit"
                    label="PV. Unidad"
                    ref="pv_unidad"
                    outlined
                    dense
                    v-on:keyup.enter="focusPrecioVentaBlister()"
                    @keypress.enter="changeSaleUnit()"
                    :error-messages="errors.get('sale_unit')"
                    @keydown="errors.clear('sale_unit')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="4"
              >
                <v-text-field
                    v-model="editedItem.sale_blister"
                    label="PV. Blister"
                    ref="pv_blister"
                    outlined
                    dense
                    v-on:keyup.enter="focusPrecioVentaCaja()"
                    @keypress.enter="changeSaleBlister()"
                    :error-messages="errors.get('sale_blister')"
                    @keydown="errors.clear('sale_blister')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="4"
              >
                <v-text-field
                    v-model="editedItem.sale_box"
                    label="PV. Caja"
                    ref="pv_caja"
                    outlined
                    dense
                    v-on:keyup.enter="focusPrecioVentaMUnidad()"
                    @keypress.enter="changeSaleBox()"
                    :error-messages="errors.get('sale_box')"
                    @keydown="errors.clear('sale_box')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="4"
              >
                <v-text-field
                    v-model="editedItem.minimum_sale_unit"
                    label="PMV. Unidad"
                    ref="pmv_unidad"
                    outlined
                    dense
                    v-on:keyup.enter="focusPrecioVentaMBlister()"
                    @keypress.enter="changeMinimumSaleUnit()"
                    :error-messages="errors.get('minimum_sale_unit')"
                    @keydown="errors.clear('minimum_sale_unit')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="4"
              >
                <v-text-field
                    v-model="editedItem.minimum_sale_blister"
                    label="PMV. Blister"
                    outlined
                    dense
                    v-on:keyup.enter="focusPrecioVentaMCaja()"
                    @keypress.enter="changeMinimumSaleBlister()"
                    :error-messages="errors.get('minimum_sale_blister')"
                    @keydown="errors.clear('minimum_sale_blister')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="4"
              >
                <v-text-field
                    v-model="editedItem.minimum_sale_box"
                    label="PMV. Caja"
                    ref="pmv_caja"
                    outlined
                    dense
                    @keypress.enter="changeMinimumSaleBox()"
                    :error-messages="errors.get('minimum_sale_box')"
                    @keydown="errors.clear('minimum_sale_box')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="4"
              >
                <v-checkbox
                  v-model="editedItem.control_expiration"
                  label="Control por vencimiento"
                  color="success"
                  value="success"
                  hide-details
                  :true-value="1"
                  :false-value="0"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="4"
              >
                <v-checkbox
                  v-model="editedItem.control_stock"
                  label="Control stock mínimo"
                  color="success"
                  value="success"
                  hide-details
                  :true-value="1"
                  :false-value="0"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="4"
              >
                <v-checkbox
                  v-model="editedItem.control_refrigeration"
                  label="Requiere refrigeración"
                  color="success"
                  value="success"
                  hide-details
                  :true-value="1"
                  :false-value="0"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="4"
              >
                <v-checkbox
                  v-model="editedItem.control_prescription"
                  label="Requiere receta"
                  color="success"
                  value="success"
                  hide-details
                  :true-value="1"
                  :false-value="0"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success darken-1"
            text
            @click="close"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="success darken-1"
            text
            @click="updateProducts"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogLaboratory"
      max-width="500px"
    >
      <v-card>
        <v-toolbar color="success" dark>NUEVO LABORATORIO</v-toolbar><br><br>
        <v-card-text>
          <v-text-field outlined dense label="Nombre" v-model="laboratory"></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="success"
            @click="saveLaboratory"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogGeneric"
      max-width="500px"
    >
      <v-card>
        <v-toolbar color="success" dark>NUEVO GENÉRICO</v-toolbar><br><br>
        <v-card-text>
          <v-text-field outlined dense label="Nombre" v-model="generic"></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="success"
            @click="saveGeneric"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogCategory"
      max-width="500px"
    >
      <v-card>
        <v-toolbar color="success" dark>NUEVA CATEGORÍA</v-toolbar><br><br>
        <v-card-text>
          <v-text-field outlined dense label="Nombre" v-model="category"></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="success"
            @click="saveCategory"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogPresentation"
      max-width="500px"
    >
      <v-card>
        <v-toolbar color="success" dark>NUEVA PRESENTACIÓN</v-toolbar><br><br>
        <v-card-text>
          <v-text-field outlined dense label="Nombre" v-model="presentation"></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="success"
            @click="savePresentation"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogLocation"
      max-width="500px"
    >
      <v-card>
        <v-toolbar color="success" dark>UBICACIÓN</v-toolbar><br><br>
        <v-card-text>
          <v-text-field outlined dense label="Nombre" v-model="location"></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="success"
            @click="saveLocation"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="text-center ma-2">
      <v-snackbar
        v-model="snackbar"
      >
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>
<script>
import SelectLocation from "../inputs/SelectLocation";
import SelectLab from "../inputs/SelectLab";
import SelectGeneric from "../inputs/SelectGeneric";
import SelectCategory from "../inputs/SelectCategory";
import SelectPresentation from "../inputs/SelectPresentation";
import axios from "axios";
import {Errors} from "../../plugins/errors";

export default {
 name: "ModalProduct",
 components: {SelectLocation, SelectLab, SelectGeneric, SelectCategory, SelectPresentation},
 props: ['items'],
 data () {
   return {
     dialog: false,
     dialogLaboratory: false,
     dialogGeneric: false,
     dialogCategory: false,
     dialogPresentation: false,
     dialogLocation: false,
     dialogDelete: false,
     snackbar: false,
     laboratories: [],
     generics: [],
     categories: [],
     presentations: [],
     locations: [],
     laboratory: '',
     generic: '',
     category: '',
     presentation: '',
     location: '',
     editedIndex: -1,
     errors: new Errors(),
     text: 'Operación realizada con éxito !!!',
     editedItem: {
       id: null,
       barcode: '',
       name: '',
       short_name: '',
       modify: '',
       laboratory: [{
         id: null,
         name: ''
       }],
       generic: [{
         id: null,
         name: ''
       }],
       category: [{
         id: null,
         name: ''
       }],
       presentation: [{
         id: null,
         name: ''
       }],
       location: [{
         id: null,
         name: ''
       }],
       maximum_stock: '',
       minimmum_stock: '',
       box_quantity: '',
       blister_quantity: '',
       presentation_sale: '',
       buy_unit: '',
       buy_blister: '',
       buy_box: '',
       sale_unit: '',
       sale_blister: '',
       sale_box: '',
       minimum_sale_unit: '',
       minimum_sale_blister: '',
       minimum_sale_box: '',
       control_expiration: 0,
       control_stock: 0,
       control_refrigeration: 0,
       control_prescription: 0,
     },
     defaultItem: {
       id: null,
       barcode: '',
       name: '',
       short_name: '',
       modify: null,
       laboratory: [{
         id: null,
         name: ''
       }],
       generic: [{
         id: null,
         name: ''
       }],
       category: [{
         id: null,
         name: ''
       }],
       presentation: [{
         id: null,
         name: ''
       }],
       location: [{
         id: null,
         name: ''
       }],
       maximum_stock: '',
       minimmum_stock: '',
       box_quantity: '',
       blister_quantity: '',
       presentation_sale: '',
       buy_unit: '',
       buy_blister: '',
       buy_box: '',
       sale_unit: '',
       sale_blister: '',
       sale_box: '',
       minimum_sale_unit: '',
       minimum_sale_blister: '',
       minimum_sale_box: '',
       control_expiration: 0,
       control_stock: 0,
       control_refrigeration: 0,
       control_prescription: 0,
     },
   }
 },
 computed: {
   compraCaja: {
     get () {
       return this.editedItem.buy_box
     },
     set (value) {
       this.editedItem.buy_box = value
     }
   },
   ventaUnidad: {
     get () {
       return this.editedItem.sale_unit
     },
     set (value) {
       this.editedItem.sale_unit = value
     }
   },
   minimoVentaUnidad: {
     get () {
       return this.editedItem.minimum_sale_unit
     },
     set (value) {
       this.editedItem.minimum_sale_unit = value
     }
   }
 },
 watch: {
   dialog (val) {
     val || this.close()
   },
   items () {
     this.editItem(this.items)
   }
 },
created () {
  this.listLaboratories()
  this.listCategories()
  this.listGenerics()
  this.listPresentations()
  this.listLocations()
},
 methods: {
   updateProducts () {
     let params = {
       'barcode'              : this.editedItem.barcode,
       'name'                 : this.editedItem.name,
       'short_name'           : this.editedItem.short_name,
       'lab_mark_id'          : this.editedItem.laboratory.id,
       'active_principle_id'  : this.editedItem.generic.id,
       'therapeutic_action_id': this.editedItem.category.id,
       'presentation_id'      : this.editedItem.presentation.id,
       'location_id'          : this.editedItem.location.id,
       'maximum_stock'        : this.editedItem.maximum_stock,
       'minimum_stock'        : this.editedItem.minimum_stock,
       'box_quantity'         : this.editedItem.box_quantity,
       'blister_quantity'     : this.editedItem.blister_quantity,
       'presentation_sale'    : this.editedItem.presentation_sale,
       'buy_unit'             : this.editedItem.buy_unit,
       'buy_blister'          : this.editedItem.buy_blister,
       'buy_box'              : this.editedItem.buy_box,
       'sale_unit'            : this.editedItem.sale_unit,
       'sale_blister'         : this.editedItem.sale_blister,
       'sale_box'             : this.editedItem.sale_box,
       'minimum_sale_unit'    : this.editedItem.minimum_sale_unit,
       'minimum_sale_blister' : this.editedItem.minimum_sale_blister,
       'minimum_sale_box'     : this.editedItem.minimum_sale_box,
       'control_expiration'   : this.editedItem.control_expiration,
       'control_stock'        : this.editedItem.control_stock,
       'control_refrigeration': this.editedItem.control_refrigeration,
       'control_prescription' : this.editedItem.control_prescription,
     }

     axios.put(`/api/products/${this.editedItem.id}`, params)
       .then(() => {
         this.close()
         this.$emit('search')
         this.snackbar = true
         this.reset()
       },error => this.errors.record(error.response.data.errors));
   },

   reset () {
     this.defaultItem = {
       id: null,
       barcode: '',
       name: '',
       short_name: '',
       modify: null,
       laboratory: [{
         id: null,
         name: ''
       }],
       generic: [{
         id: null,
         name: ''
       }],
       category: [{
         id: null,
         name: ''
       }],
       presentation: [{
         id: null,
         name: ''
       }],
       location: [{
         id: null,
         name: ''
       }],
       maximum_stock: '',
       minimmum_stock: '',
       box_quantity: '',
       blister_quantity: '',
       presentation_sale: '',
       buy_unit: '',
       buy_blister: '',
       buy_box: '',
       sale_unit: '',
       sale_blister: '',
       sale_box: '',
       minimum_sale_unit: '',
       minimum_sale_blister: '',
       minimum_sale_box: '',
       control_expiration: 0,
       control_stock: 0,
       control_refrigeration: 0,
       control_prescription: 0
     }
   },

   saveLaboratory () {
     const createLaboratory = {
       'name': this.laboratory
     }
     axios.post('/api/products/laboratory', createLaboratory)
       .then(() => {
         this.listLaboratories()
         this.closeLaboratory()
       },error => this.lab_errors.record(error.response.data.errors));
   },

   saveGeneric () {
     const createGeneric = {
       'name': this.generic
     }
     axios.post('/api/products/generic', createGeneric)
       .then(() => {
         this.listGenerics()
         this.closeGeneric()
       },error => this.generic_errors.record(error.response.data.errors));
   },

   saveCategory () {
     const createCategory = {
       'name': this.category
     }
     axios.post('/api/products/category', createCategory)
       .then(() => {
         this.listCategories()
         this.closeCategory()
       },error => this.category_errors.record(error.response.data.errors));
   },

   savePresentation () {
     const createPresentation = {
       'name': this.presentation
     }
     axios.post('/api/products/presentation', createPresentation)
       .then(() => {
         this.listPresentations()
         this.closePresentation()
       },error => this.presentation_errors.record(error.response.data.errors));
   },

   saveLocation () {
     const createLocation = {
       'name': this.location
     }
     axios.post('/api/products/location', createLocation)
       .then(() => {
         this.listLocations()
         this.closeLocation()
       },error => this.location_errors.record(error.response.data.errors));
   },

   listLaboratories () {
     axios.get('/api/products/list/laboratories')
       .then(response => {
         if (Object.entries(response.data.laboratories).length === 0) {
           this.laboratories = this.parentModify
         } else {
           this.laboratories = response.data.laboratories
         }
       })
   },

   listGenerics () {
     axios.get('/api/products/list/generics')
       .then(response => {
         if (Object.entries(response.data.generics).length === 0) {
           this.generics = this.parentModify
         } else {
           this.generics = response.data.generics
         }
       })
   },

   listCategories () {
     axios.get('/api/products/list/categories')
       .then(response => {
         if (Object.entries(response.data.categories).length === 0) {
           this.categories = this.parentModify
         } else {
           this.categories = response.data.categories
         }
       })
   },

   listPresentations () {
     axios.get('/api/products/list/presentations')
       .then(response => {
         if (Object.entries(response.data.presentations).length === 0) {
           this.presentations = this.parentModify
         } else {
           this.presentations = response.data.presentations
         }
       })
   },

   listLocations () {
     axios.get('/api/products/list/locations')
       .then(response => {
         if (Object.entries(response.data.locations).length === 0) {
           this.locations = this.parentModify
         } else {
           this.locations = response.data.locations
         }
       })
   },

   editItem (item) {
     this.editedItem = Object.assign({}, item)
     this.dialog = true
   },

   close () {
     this.dialog = false
     this.$nextTick(() => {
       this.editedItem = Object.assign({}, this.defaultItem)
     })
     this.errors.record('')
     this.reset()
     this.editedItem.modify = null
   },

   closeLaboratory () {
     this.dialogLaboratory = false
     this.laboratory = ''
   },

   closeGeneric () {
     this.dialogGeneric = false
     this.generic = ''
   },

   closeCategory () {
     this.dialogCategory = false
     this.category = ''
   },

   closePresentation () {
     this.dialogPresentation = false
     this.presentation = ''
   },

   closeLocation () {
     this.dialogLocation = false
     this.location = ''
   },

   changeBuyUnit () {
     this.editedItem.buy_blister = (parseFloat(this.editedItem.buy_unit) * parseFloat(this.editedItem.blister_quantity)).toFixed(4)
     this.editedItem.buy_box     = (parseFloat(this.editedItem.buy_unit) * parseFloat(this.editedItem.box_quantity)).toFixed(2)
   },
   changeBuyBox () {
     this.editedItem.buy_unit    = parseFloat(parseFloat(this.editedItem.buy_box) / parseFloat(this.editedItem.box_quantity)).toFixed(4)
     this.editedItem.buy_blister = (parseFloat(this.editedItem.buy_unit) * parseFloat(this.editedItem.blister_quantity)).toFixed(4)
   },
   changeSaleUnit () {
     this.editedItem.sale_blister         = (parseFloat(this.editedItem.sale_unit) * parseFloat(this.editedItem.blister_quantity)).toFixed(2)
     this.editedItem.sale_box             = (parseFloat(this.editedItem.sale_unit) * parseFloat(this.editedItem.box_quantity)).toFixed(2)
     this.editedItem.minimum_sale_unit    = this.editedItem.sale_unit
     this.editedItem.minimum_sale_blister = this.editedItem.sale_blister
     this.editedItem.minimum_sale_box     = this.editedItem.sale_box
   },
   changeSaleBox () {
     this.editedItem.minimum_sale_box = this.editedItem.sale_box
   },
   changeMinimumSaleUnit () {
     this.editedItem.minimum_sale_blister = (parseFloat(this.editedItem.minimum_sale_unit) * parseFloat(this.editedItem.blister_quantity)).toFixed(2)
     this.editedItem.minimum_sale_box     = (parseFloat(this.editedItem.minimum_sale_unit) * parseFloat(this.editedItem.box_quantity)).toFixed(2)
   },
   changeMinimumSaleBox () {
     //this.editedItem.minimum_sale_unit    = (parseFloat(this.editedItem.minimum_sale_box) / parseFloat(this.editedItem.box_quantity)).toFixed(2)
     //this.editedItem.minimum_sale_blister = (parseFloat(this.editedItem.minimum_sale_unit) * parseFloat(this.editedItem.blister_quantity)).toFixed(2)
   },
   changeBuyBlister () {
     this.editedItem.buy_box = (this.editedItem.buy_blister * parseInt(this.editedItem.box_quantity / this.editedItem.blister_quantity)).toFixed(2)
   },
   changeSaleBlister () {
     this.editedItem.sale_box = ((parseFloat(this.editedItem.sale_blister) / parseFloat(this.editedItem.blister_quantity)) * parseFloat(this.editedItem.box_quantity)).toFixed(2)
     this.editedItem.minimum_sale_blister = this.editedItem.sale_blister
     this.editedItem.minimum_sale_box     = this.editedItem.sale_box
   },
   changeMinimumSaleBlister () {
     this.editedItem.minimum_sale_box = (this.editedItem.minimum_sale_blister * parseInt(this.editedItem.box_quantity / this.editedItem.blister_quantity)).toFixed(2)
   },
   focusName () {
     this.$refs.name.focus()
   },
   focusStockMaximo () {
     this.$refs.aximum_stock.focus()
   },
   focusStockMinimo () {
     this.$refs.inimum_stock.focus()
   },
   focusCantidadCaja () {
     this.$refs.cantidadCaja.focus()
   },
   focusCantidadBlister () {
     this.$refs.cantidadBlister.focus()
   },
   focusCantidadPresentacion () {
     this.$refs.cantidadPresentacion.focus()
   },
   focusPrecioCaja () {
     this.$refs.pc_caja.focus()
   },
   focusPrecioVentaUnidad () {
     this.$refs.pv_unidad.focus()
   },
   focusPrecioVentaBlister () {
     this.$refs.pv_blister.focus()
   },
   focusPrecioVentaCaja () {
     this.$refs.pv_caja.focus()
   },
   focusPrecioVentaMUnidad () {
     this.$refs.pmv_unidad.focus()
   },
   focusPrecioVentaMBlister () {
     this.$refs.pmv_blister.focus()
   },
   focusPrecioVentaMCaja () {
     this.$refs.pmv_caja.focus()
   },
 }
}
</script>

<style scoped>
.inputs-container,
.col-12 {
  padding-top: 0;
  padding-bottom: 0;
}
.theme--light.v-input {
  padding-top: 0;
}
.v-input__slot {
  margin-bottom: 0;
}
.v-progress-circular {
  margin: 1rem;
}
</style>
