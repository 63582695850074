<template>
  <v-col
    cols="12"
    sm="11"
    md="11"
  >
    <v-autocomplete
      v-model="editedItem.generic.id"
      :items="generics"
      label="Principio Activo"
      outlined
      dense
      clearable
      item-text="name"
      item-value="id"
    ></v-autocomplete>
  </v-col>
</template>

<script>
export default {
name: "SelectGeneric",
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    generics: Array,
  },
  computed: {

  },
  watch: {
    generics () {
      this.editedItem.generic.id = this.generics[0].id
    },

  }
}
</script>
