<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="products"
      class="elevation-2"
      hide-default-footer
      @click:row="selectRow"
      item-key="index"
      :footer-props="{ 'items-per-page-options': [100] }"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-4"
          @click.stop="showProducts(item)"
        >
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
    <ModalProduct @search="search" :items="items"/>
  </div>
</template>
<script>
import ModalProduct from "../modals/ModalProduct";
export default {
  name: "TableProductPurchases",
  components: {ModalProduct},
  props: ['products', 'flag'],
  data () {
    return {
      headers: [
        { text: 'CODIGO DE BARRA',    value: 'barcode',              sortable: false, width: "150px", class: "success white--text"},
        { text: 'NOMBRE',             value: 'name',              sortable: false, width: "400px", class: "success white--text"},
        { text: 'STOCK',              value: 'stock.stock',    sortable: false, width: "50px", class: "success white--text"},
        { text: 'LABORATORIO',        value: 'laboratory.name',   sortable: false, width: "100px", class: "success white--text"},
        { text: 'PRINCIPIO ACTIVO',   value: 'generic.name',      sortable: false, width: "100px", class: "success white--text"},
        { text: 'ACCIÓN TERAPEÚTICA', value: 'category.name',     sortable: false, width: "100px", class: "success white--text"},
        { text: 'UNIDAD MEDIDA',      value: 'presentation.name', sortable: false, width: "50px",  class: "success white--text"},
        { text: 'PRECIO COMPRA',      value: 'buy_unit',         sortable: false, width: "50px",  class: "success white--text"},
        { text: 'PRECIO VENTA',       value: 'sale_unit',         sortable: false, width: "50px",  class: "success white--text"},
        { text: 'ACCIONES',           value: 'actions',           sortable: false, width: "50px",  class: "success white--text"},
      ],
      purchases: [],
      items: ''
    }
  },
  watch: {
    products () {
      if (this.products.length > 0 && this.flag === true) {
        this.selectRow(this.products[0])
      }
    }
  },
  created () {
    if (this.products.length > 0 && this.flag === true) {
      this.selectRow(this.products[0])
    }
  },
  methods: {
    showProducts (item) {
      this.items = item
    },
    selectRow (row) {

      //if (row.stock.stock !== null && row.stock.stock !== 0) {
      //  const indexItem = this.purchases.findIndex(item => item.id === row.id)
      //  console.log(indexItem)
      /*  if(indexItem >= 0){
          this.purchases.filter(value => {
            if (value.box_quantity > 1 && value.id === row.id) {
              let valFirst = 0
              let valLast = 0
              const values = value.quantity.split("F")

              for (let i = 0; i < values.length; i++) {
                valFirst = values[0]
                valLast = values[1]
              }

              if (parseInt(valLast) >= value.box_quantity) {
                valFirst = parseInt(valFirst) + 1
                return value.quantity =  `${valFirst}F0`
              }else {
                valLast = parseInt(valLast) + 1
                return value.quantity =  `${valFirst}F${valLast}`
              }
            }else if (value.id === row.id) {
              return value.quantity = parseInt(value.quantity) + 1
            }
          })
        }*/
        //else{
      const indexItem = this.purchases.findIndex(item => item.row_invoice === row.row_invoice && item.id === row.id)
      let increment = 1
      if (indexItem >= 0) {
        row.row_invoice++
        console.log(row.row_invoice)
      } else {
        row.row_invoice = increment
      }
      if (row.box_quantity > 1) {
        row.quantity = '0F1'
      }else {
        row.quantity = '1'
      }
      this.purchases.push(row)
        //}
      this.$emit('purchases', this.purchases)
      //}
    },
    search () {
      this.$emit('searchValue')
    }
  }
}
</script>

<style scoped>

</style>
