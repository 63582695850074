<template>
  <div>
    <v-container fluid>
      <v-card color="grey lighten-5" elevation="0">
        <v-card-title>
          <h3 class="font-weight-black display-0 basil--text">
            COMPROBANTE DE COMPRAS
          </h3>
        </v-card-title>
      </v-card><br>
      <v-card elevation="2" class="mb-6">
        <v-row class="pt-6 mx-auto">
          <v-col md="7" sm="12" xs="12">
            <v-row>
              <v-col
                cols="12"
                sm="12"
                xs="12"
                md="3"
              >
                <v-text-field
                  v-model="editedItem.person.ruc"
                  outlined
                  dense
                  ref="ruc"
                  label="RUC"
                  v-uppercase
                  v-on:keyup.enter="focusBusinessName()"
                  :error-messages="errors.get('ruc')"
                  @keydown="errors.clear('ruc')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                xs="12"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.person.businessName"
                  ref="businesName"
                  outlined
                  dense
                  v-uppercase
                  label="Razón social"
                  v-on:keyup.enter="focusDireccion()"
                  :error-messages="errors.get('businessName')"
                  @keydown="errors.clear('businessName')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                xs="12"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.person.direction"
                  ref="direccion"
                  outlined
                  dense
                  v-uppercase
                  v-on:keyup.enter="focusCelular()"
                  label="Dirección"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                xs="12"
                md="3"
              >
                <v-text-field
                  v-model="editedItem.person.phone"
                  ref="celular"
                  outlined
                  dense
                  v-on:keyup.enter="focusCorreo()"
                  label="Celular"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                xs="12"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.person.email"
                  ref="correo"
                  outlined
                  dense
                  v-uppercase
                  v-on:keyup.enter="focusServicio()"
                  label="Correo electrónico"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                xs="12"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.supplier"
                  ref="servicio"
                  outlined
                  dense
                  v-uppercase
                  v-on:keyup.enter="focusTipoCompra()"
                  label="Servicio"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="5" sm="12" xs="12">
            <v-row>
              <v-col
                cols="12"
                sm="12"
                xs="12"
                md="3"
              >
                <v-autocomplete
                  v-model="editedItem.type_invoice_purchase_id"
                  :items="listPurchases"
                  ref="tipoCompra"
                  item-text="description"
                  item-value="id"
                  outlined
                  dense
                  label="Tipo compra"
                  v-on:keyup.enter="focusNumero()"
                  :error-messages="errors.get('type_invoice_purchase_id')"
                  @keydown="errors.clear('type_invoice_purchase_id')"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                xs="12"
                md="3"
              >
                <v-text-field
                  v-model="editedItem.number"
                  ref="numero"
                  outlined
                  dense
                  label="Número"
                  v-on:keyup.enter="focusFecha()"
                  :error-messages="errors.get('number')"
                  @keydown="errors.clear('number')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="3"
              >
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.date"
                      label="Fecha compra"
                      ref="fecha"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      outlined
                      dense
                      v-on:keyup.enter="focusBuscarProducto()"
                      @blur="date = parseDate(editedItem.date)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    @input="menu = false"
                    color="green lighten-1"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="4"
              >
                <v-checkbox
                  v-model="editedItem.igv"
                  label="IGV"
                  color="success"
                  value="success"
                  hide-details
                  :true-value="1"
                  :false-value="0"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-card elevation="2" class="mb-3">
        <v-col
          sm="12"
          xs="12"
          md="3"
          class="pt-7 pb-0"
        >
          <v-text-field
            v-model="search"
            ref="buscarProducto"
            outlined
            dense
            label="Buscar Producto"
            @keyup="filterProducts(search)"
            @keyup.enter="filterBarcode(search)"
            :append-icon="svgPath"
          ></v-text-field>
        </v-col>
      </v-card>
      <TableProductPurchases
        v-if="products.length"
        v-show="showTableProduct"
        :products="products"
        :flag="flagBarcode"
        @purchases="purchases"
        @searchValue="searchValue"
        :resetProducts="resetProducts"
      />
      <TablePurchase
        v-show="showTablePurchase"
        :purchases="purchase"
        :resetPurchases="resetPurchases"
        :editedItem="editedItem"
        @remove="removeList"
        @listProducts="listProducts($event)"
        @save="saveInvoicePurchase"
        @cancel="cancelInvoicePurchase"
      />
    </v-container>
    <div class="text-center ma-2">
      <v-snackbar
        v-model="snackbar"
      >
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <div class="text-center ma-2">
      <v-snackbar
        v-model="snackbar2"
        color="deep-purple accent-4"
        bottom
      >
        {{ text2 }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar2 = false"
          >
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>
<script>
import {mdiMagnify} from '@mdi/js';
import TableProductPurchases from "../../components/tables/TableProductPurchases";
import axios from "axios";
import TablePurchase from "../../components/tables/TablePurchase";
import {Errors} from "../../plugins/errors";
import {mapState} from "vuex";
import { format, parseISO } from 'date-fns'
export default {
  name: "PurchaseInvoice",
  components: {TablePurchase, TableProductPurchases},
  data () {
    return {
      svgPath: mdiMagnify,
      products: [],
      purchase: [],
      listPurchases: [],
      selectProducts: [],
      resetProducts: [],
      resetPurchases: [],
      menu: false,
      snackbar: false,
      snackbar2: false,
      showTableProduct: false,
      showTablePurchase: true,
      search: '',
      text: 'Operación realizada con éxito !!!',
      text2: 'Debes de ingresar lote !!!',
      errors: new Errors(),
      updateSearch: '',
      flagBarcode: false,
      date: new Date().toISOString().substr(0, 10),
      opening_date: new Date().toISOString().substr(0, 10),
      editedItem: {
        person: {
          ruc: '',
          phone: '',
          email: '',
          businessName: '',
          durection: '',
          firstName: '',
          lastName: '',
        },
        supplier: '',
        type_invoice_purchase_id: 1,
        number: '',
        date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        igv: 0,
        subtotal: 0.0,
        total: 0.0
      },
      parentLaboratory: {
        id: null,
        name: ''
      },
      parentGeneric: {
        id: null,
        name: ''
      },
      parentCategory: {
        id: null,
        name: ''
      },
      parentPresentation: {
        id: null,
        name: ''
      },
      parentLocation: {
        id: null,
        name: ''
      },
    }
  },
  computed: {
    opening: {
      get () {
        return this.opening_date
      },
      set (val) {
        this.opening_date = val
      }
    },
    ruc () {
      return this.editedItem.person.ruc
    },
    ...mapState('auth', ['user'])
  },
  watch: {
    date () {
      this.editedItem.date = this.formatDate(this.date)
    },
    opening () {
      this.editedItem.date = this.formatDate(this.opening_date)
    },
    ruc (search) {
      if (search.length === 11) {
        axios.get(`/api/assignments/filter/legal?search=${search}`)
          .then(response => {
            if (response.data.data.length) {
              this.editedItem.person = response.data.data['0']
            }
          })
          .catch(error => console.log(error))
      }
    }
  },
  mounted() {
    this.focusInput()
    this.listTypeInvoicePurchases()
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}-${month}-${day}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    filterProducts (search) {
      if (search.length >= 4) {
        let modifyList = []
        let quotient = 0
        let rest     = 0
        axios.get(`/api/invoices/products/${search}`)
          .then(response => {
            modifyList = response.data.data
            if (modifyList.length > 0) {
              modifyList.filter((value) => {
                return value.laboratory == null ? value.laboratory = this.parentLaboratory : value.laboratory
              })
              modifyList.filter((value) => {
                return value.generic == null ? value.generic = this.parentGeneric : value.generic
              })
              modifyList.filter((value) => {
                return value.category == null ? value.category = this.parentCategory : value.category
              })
              modifyList.filter((value) => {
                return value.presentation == null ? value.presentation = this.parentPresentation : value.presentation
              })
              modifyList.filter((value) => {
                return value.location == null ? value.location = this.parentLocation : value.location
              })

              modifyList.filter((value) => {
                if (value.stock !== null) {
                  if (value.box_quantity > 1) {
                    quotient = parseInt(value.stock.stock) % parseInt(value.box_quantity)
                    rest  = (parseInt(value.stock.stock) - parseInt(quotient)) / parseInt(value.box_quantity)
                    value.stock.stock = rest + 'F' + quotient
                  }else {
                    value.stock.stock = parseInt(value.stock.stock)
                  }

                  return value.stock
                }
              })

              this.products = modifyList
              this.products.length ? this.showTableProduct = true : this.showTableProduct = false
              this.showTablePurchase = false
            }else {
              this.showTableProduct = false
              this.showTablePurchase = true
            }
          })
          .catch(error => console.log(error))
      } else {
        this.showTableProduct = false
        this.showTablePurchase = true
      }
    },
    filterBarcode (search) {
      let modifyList = []
      axios.get(`/api/ticket/barcode/${search}`)
        .then(response => {
          modifyList = response.data.data
          if (modifyList.length > 0) {
            modifyList.filter((value) => {
              return value.laboratory == null ? value.laboratory = this.parentLaboratory : value.laboratory
            })
            modifyList.filter((value) => {
              return value.generic == null ? value.generic = this.parentGeneric : value.generic
            })
            modifyList.filter((value) => {
              return value.category == null ? value.category = this.parentCategory : value.category
            })
            modifyList.filter((value) => {
              return value.presentation == null ? value.presentation = this.parentPresentation : value.presentation
            })
            modifyList.filter((value) => {
              return value.location == null ? value.location = this.parentLocation : value.location
            })
            this.products = modifyList
            this.flagBarcode = true
          }else {
            return ''
          }
        })
        .catch(error => console.log(error))
    },
    listTypeInvoicePurchases () {
      axios.get('/api/invoices/type')
        .then(response => this.listPurchases = response.data.listPurchases)
        .catch(error => console.log(error))
    },
    listProducts ($event) {
      const indexItem = this.selectProducts.findIndex(x => x.id === $event.id);
      if(indexItem >= 0){
        return ''
      }else{
        this.selectProducts = $event
      }
    },
    saveInvoicePurchase () {

      let lot = this.selectProducts.map(
          l => {
            if (l.lot === '' && l.control_expiration !== 0) {
              return 'vacio'
            }else {
              return 'Valor'
            }
          }
      )

      let respuesta = lot.find(l=>l === 'vacio')

      if (respuesta === 'vacio') {
        this.snackbar2 = true
      }else {
        let createInvoicePurchase = {
        'ruc'                     : this.editedItem.person.ruc,
        'businessName'            : this.editedItem.person.businessName,
        'direction'               : this.editedItem.person.direction,
        'firstName'               : this.editedItem.person.firstName,
        'lastName'                : this.editedItem.person.lastName,
        'phone'                   : this.editedItem.person.phone,
        'email'                   : this.editedItem.person.email,
        'supplier'                : this.editedItem.supplier,
        'number'                  : this.editedItem.number,
        'date'                    : this.editedItem.date,
        'subtotal'                : this.editedItem.subtotal,
        'total'                   : this.editedItem.total,
        'type_invoice_purchase_id': this.editedItem.type_invoice_purchase_id,
        'purchases'               : this.selectProducts,
        'area_assignment_id'      : this.user[0].id,
        'entity_id'               : this.user[0].area.entity.id
      }

      axios.post('/api/invoices', createInvoicePurchase)
        .then(() => {
          this.showTablePurchase = true
          this.snackbar = true
          this.reset()
        },error => this.errors.record(error.response.data.errors));
      }
    },
    focusInput () {
      this.$refs.ruc.focus();
    },
    purchases (row) {
      this.purchase = row
      this.showTablePurchase = true
      this.showTableProduct  = false
      this.search = ''
    },
    removeList ($event) {
      const index = this.purchase.findIndex(item => item.id === $event)
      this.purchase.splice(index, 1)
    },
    reset () {
      this.selectProducts = []
      this.products       = []
      this.purchase       = []
      this.resetPurchases = []
      this.search         = ''
      this.errors = new Errors()
      this.editedItem = {
        person: {
          ruc: '',
          phone: '',
          email: '',
          businessName: '',
          direction: '',
          firstName: '',
          lastName: '',
        },
        supplier: '',
        type_invoice_purchase_id: 2,
        number: '',
        date: new Date().toISOString().substr(0, 10),
        igv: 0,
        subtotal: 0.0,
        total: 0.0
      }
    },
    cancelInvoicePurchase () {
      this.reset()
    },
    searchValue () {
      this.filterProducts(this.search)
    },
    focusBusinessName() {
      this.$refs.businesName.focus()
    },
    focusDireccion() {
      this.$refs.direccion.focus()
    },
    focusCelular() {
      this.$refs.celular.focus()
    },
    focusCorreo() {
      this.$refs.correo.focus()
    },
    focusServicio() {
      this.$refs.servicio.focus()
    },
    focusTipoCompra() {
      this.$refs.tipoCompra.focus()
    },
    focusNumero() {
      this.$refs.numero.focus()
    },
    focusFecha() {
      this.$refs.fecha.focus()
    },
    focusBuscarProducto() {
      this.$refs.buscarProducto.focus()
    },
  }
}
</script>

<style scoped>
  /* Helper classes */
  .basil {
    background-color: #FFFBE6 !important;
  }
  .basil--text {
    color: #356859 !important;
  }
  .inputs-container,
  .col-12 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .theme--light.v-input {
    padding-top: 0;
  }
  .v-input__slot {
    margin-bottom: 0;
  }
</style>
