<template>
  <v-col
    cols="12"
    sm="11"
    md="11"
  >
    <v-autocomplete
      v-model="editedItem.laboratory.id"
      :items="laboratories"
      label="Laboratorio/Marca"
      outlined
      dense
      clearable
      item-text="name"
      item-value="id"
    >
    </v-autocomplete>
  </v-col>
</template>

<script>
export default {
name: "SelectLab",
  data () {
    return {

    }
  },
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    laboratories: Array,
  },
  computed: {

  },
  watch: {
    laboratories () {
      this.editedItem.laboratory.id = this.laboratories[0].id
    },
  },

}
</script>
