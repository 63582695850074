<template>
    <tr>
    <td>{{index + 1}}</td>
    <td v-if="item">
      <span>{{item.name}}</span>
    </td>
    <td v-if="item.laboratory">
      <span>{{item.laboratory.name}}</span>
    </td>
    <td v-else>
      <span></span>
    </td>
    <td v-if="item.control_expiration !== 0">
      <span v-if="!editable">{{ item.lot }}</span>
      <v-text-field
        v-else
        v-model="item.lot"
        outlined
        dense
        class="mt-6 mr-0"
      ></v-text-field>
    </td>
    <td v-else>
      <v-text-field
        v-model="item.lot"
        outlined
        dense
        disabled
        class="mt-6 mr-0"
      ></v-text-field>
    </td>
    <td>
      <span v-if="!editable">{{ item.expiration_date }}</span>
      <v-menu
        v-else
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="item.expiration_date"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="item.expiration_date"
            readonly
            outlined
            dense
            class="mt-6"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="item.expiration_date"
          type="month"
          no-title
          scrollable
          color="green lighten-1"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="success"
            @click="menu = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            text
            color="success"
            @click="$refs.menu.save(item.expiration_date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </td>
    <td v-if="item.box_quantity > 1">
      <span v-if="!editable">{{ item.quantity }}</span>
      <v-text-field
        v-else
        v-model="item.quantity"
        outlined
        dense
        class="mt-6"
        v-uppercase
        solo-inverted
        @change="changeQuantity(item.quantity)"
        @input="up"
        ref="qua"
      ></v-text-field>
    </td>
    <td v-else>
      <span v-if="!editable">{{ item.quantity }}</span>
      <v-text-field
        v-else
        v-model="item.quantity"
        outlined
        dense
        class="mt-6"
        solo-inverted
        sm="6"
        @input="changeQuantity(item.quantity)"
        type="number"
        min="1"
        step="1"
        ref="qua"
      ></v-text-field>
    </td>
    <td v-if="item">
      <span>{{ item.buy_unit }}</span>
    </td>
    <td v-if="item">
      <span v-if="!editable">{{ item.buy_unit  }}</span>
      <v-text-field
        v-else
        v-model="item.buy_unit "
        outlined
        dense
        :label=" parseInt(item.presentation_sale)+' Unid'"
        @keypress.enter="changeBuyUnit(item.buy_unit )"
        class="mt-6"
      ></v-text-field>
    </td>
    <td v-if="item">
      <span v-if="!editable">{{ item.buy_blister }}</span>
      <v-text-field
        v-else
        v-model="item.buy_blister"
        outlined
        dense
        :label="item.blister_quantity+ ' Unid '"
        @keypress.enter="changeBuyBlister(item.buy_blister )"
        class="mt-6"
      ></v-text-field>
    </td>
    <td v-if="item">
      <span v-if="!editable">{{ item.buy_box }}</span>
      <v-text-field
        v-else
        v-model="item.buy_box"
        outlined
        dense
        :label="item.box_quantity + ' Unid ' "
        @keypress.enter="changeBuyBox(item.buy_blister )"
        class="mt-6"
      ></v-text-field>
    </td>
    <td v-if="item">
      <span v-if="!editable">{{ item.sale_unit  }}</span>
      <v-text-field
        v-else
        v-model="item.sale_unit "
        outlined
        dense
        @keypress.enter="changeSaleUnit(item.sale_unit )"
        class="mt-6"
      ></v-text-field>
    </td>
    <td v-if="item">
      <span v-if="!editable">{{ item.sale_blister }}</span>
      <v-text-field
        v-else
        v-model="item.sale_blister"
        outlined
        dense
        @keypress.enter="changeSaleBlister(item.sale_blister )"
        class="mt-6"
      ></v-text-field>
    </td>
    <td v-if="item">
      <span v-if="!editable">{{ item.sale_box }}</span>
      <v-text-field
        v-else
        v-model="item.sale_box"
        outlined
        dense
        @keypress.enter="changeSaleBox(item.sale_box)"
        class="mt-6"
      ></v-text-field>
    </td>
    <td v-if="item">
      <span v-if="!editable">{{ item.minimum_sale_unit  }}</span>
      <v-text-field
        v-else
        v-model="item.minimum_sale_unit"
        outlined
        dense
        @keypress.enter="changeMinimumSaleUnit(item.minimum_sale_unit )"
        class="mt-6"
      ></v-text-field>
    </td>
    <td v-if="item">
      <span v-if="!editable">{{ item.minimum_sale_blister }}</span>
      <v-text-field
        v-else
        v-model="item.minimum_sale_blister"
        outlined
        dense
        @keypress.enter="changeMinimumSaleBlister(item.minimum_sale_blister )"
        class="mt-6"
      ></v-text-field>
    </td>
    <td v-if="item">
      <span v-if="!editable">{{ item.minimum_sale_box }}</span>
      <v-text-field
        v-else
        v-model="item.minimum_sale_box"
        outlined
        dense
        class="mt-6"
      ></v-text-field>
    </td>
    <td v-if="item.total === totalPartial">
      <span>{{ totalPartial | currency }}</span>
    </td>
    <td v-else>
      <span>{{ item.total | currency }}</span>
    </td>
    <td>
      <v-icon
        v-if="editable"
        small
        class="mr-2"
        @click="toggleEditable()"
      >
        {{ icons.mdiCheckBold }}
      </v-icon>
      <v-icon
        v-if="editable"
        small
        class="mr-2"
        @click="removeItem()"
      >
        {{ icons.mdiDelete }}
      </v-icon>
      <template v-else>
      <v-icon
        small
        class="mr-2"
        @click="toggleEditable()"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        class="mr-2"
        @click="removeItem()"
      >
        {{ icons.mdiDelete }}
      </v-icon>
      </template>
    </td>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline">¿Esta seguro de eliminar este registro?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="closeDelete">Cancel</v-btn>
            <v-btn color="success" text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </tr>
</template>
<script>

import {mdiAccount, mdiCheckBold, mdiDelete, mdiPencil, mdiShareVariant,} from '@mdi/js'

export default {
  name: "Row",
  props: ['item', 'index'],
  data() {
    return {
      icons: {
        mdiAccount,
        mdiPencil,
        mdiShareVariant,
        mdiDelete,
        mdiCheckBold
      },
      dialogDelete: false,
      editable: false,
      menu: false,
      modal: false,
      totalPartial: 0.0,
      date: null
    }
  },
  computed: {
    total: {
      get () {
        return this.totalPartial
      },
      set (value) {
        this.totalPartial = value
      }
    },
    quantity: {
      get () {
        return this.item.quantity
      },
      set (value) {
        this.item.quantity = value
      }
    }
  },
  watch: {
    total () {
      this.$emit('total')
    },
    quantity () {
      this.changeQuantity(this.item.quantity)
    }
  },
  created (){
    this.item.lot = ''
    this.item.total = 0.0
    this.item.product_quantity = 0.0

    this.editable = true
    this.item.expiration_date = new Date().toISOString().substr(0, 7)
    this.changeQuantity(this.item.quantity)
  },
  mounted() {

  },
  methods: {
    toggleEditable() {
      this.editable = !this.editable;
    },

    changeQuantity (val) {
      if (val.toString().includes("F")) {
        if (this.item.box_quantity > 1) {
          let valFirst = 0
          let valLast = 0
          const values = val.split("F")
          for (let i = 0; i < values.length; i++) {
            valFirst = values[0]
            valLast = values[1]
          }
          if (valLast) {
            this.item.product_quantity = (parseFloat(valFirst) * parseFloat(this.item.box_quantity) + parseFloat(valLast)).toFixed(2)
            this.item.total            = (parseFloat(this.item.product_quantity) * parseFloat(this.item.buy_unit)).toFixed(2)
            this.totalPartial          = this.item.total
          }
        }
      }else{
        this.item.total   = (parseFloat(this.item.buy_unit) * parseFloat(val)).toFixed(2)
        this.totalPartial = this.item.total
      }
      this.$emit('dataRow', this.item)
    },

    changeBuyUnit () {
      this.item.buy_blister = (parseFloat(this.item.buy_unit) * parseFloat(this.item.blister_quantity)).toFixed(4)
      this.item.buy_box     = (parseFloat(this.item.buy_unit) * parseFloat(this.item.box_quantity)).toFixed(2)
    },
    changeBuyBox () {
      this.item.buy_unit    = parseFloat(parseFloat(this.item.buy_box) / parseFloat(this.item.box_quantity)).toFixed(4)
      this.item.buy_blister = (parseFloat(this.item.buy_unit) * parseFloat(this.item.blister_quantity)).toFixed(4)
      this.item.buy_box     = parseFloat(this.item.buy_box).toFixed(2)
      this.changeQuantity(this.item.quantity)

    },
    changeSaleUnit () {
      this.item.sale_blister         = (parseFloat(this.item.sale_unit) * parseFloat(this.item.blister_quantity)).toFixed(2)
      this.item.sale_box             = (parseFloat(this.item.sale_unit) * parseFloat(this.item.box_quantity)).toFixed(2)
      this.item.minimum_sale_unit    = parseFloat(this.item.sale_unit).toFixed(2)
      this.item.minimum_sale_blister = parseFloat(this.item.sale_blister).toFixed(2)
      this.item.minimum_sale_box     = parseFloat(this.item.sale_box).toFixed(2)
      this.item.sale_unit            = parseFloat(this.item.sale_unit).toFixed(2)
    },
    changeSaleBox () {
      //this.item.sale_unit    = (parseFloat(this.item.sale_box) / parseFloat(this.item.box_quantity)).toFixed(2)
      //this.item.sale_blister = (parseFloat(this.item.sale_unit) * parseFloat(this.item.blister_quantity)).toFixed(2)
      this.item.minimum_sale_box     = parseFloat(this.item.sale_box).toFixed(2)
      this.item.sale_box             = parseFloat(this.item.sale_box).toFixed(2)
    },
    changeMinimumSaleUnit () {
      this.item.minimum_sale_blister = (parseFloat(this.item.minimum_sale_unit) * parseFloat(this.item.blister_quantity)).toFixed(2)
      this.item.minimum_sale_box     = (parseFloat(this.item.minimum_sale_unit) * parseFloat(this.item.box_quantity)).toFixed(2)
    },
    changeMinimumSaleBox () {
      this.item.minimum_sale_unit    = (parseFloat(this.item.minimum_sale_box) / parseFloat(this.item.box_quantity)).toFixed(2)
      this.item.minimum_sale_blister = (parseFloat(this.item.minimum_sale_unit) * parseFloat(this.item.blister_quantity)).toFixed(2)
    },
    changeBuyBlister () {
      this.item.buy_box = (this.item.buy_blister * parseInt(this.item.box_quantity / this.item.blister_quantity)).toFixed(2)
    },
    changeSaleBlister () {
      this.item.sale_box = (this.item.sale_blister * parseInt(this.item.box_quantity / this.item.blister_quantity)).toFixed(2)
      this.item.minimum_sale_blister = parseFloat(this.item.sale_blister).toFixed(2)
      this.item.minimum_sale_box     = parseFloat(this.item.sale_box).toFixed(2)
      this.item.sale_blister= parseFloat(this.item.sale_blister).toFixed(2)
    },
    changeMinimumSaleBlister () {
      this.item.minimum_sale_box = (this.item.minimum_sale_blister * parseInt(this.item.box_quantity / this.item.blister_quantity)).toFixed(2)
    },
    removeItem () {
      this.dialogDelete = true
    },
    remove () {
      this.$emit('removeList', this.item.id)
    },
    closeDelete () {
      this.dialogDelete = false
    },
    deleteItemConfirm () {
      this.remove()
      this.closeDelete()
    },

    up (){
      if (parseInt(this.item.quantity) > -1 && !this.item.quantity.includes('F')) {
        this.$nextTick(() => {
          this.item.quantity =  parseInt(this.item.quantity) +'F0'
        })
      }
      if (this.item.quantity.includes('F')){
        let valFirst = 0
        let valLast  = 0
        let resto = 0
        let cocient = 0

        const value=this.item.quantity.split("F")
        for (let i = 0; i < this.item.quantity.length; i++) {
          valFirst = value[0]
          valLast  = value[1]
        }

        if (valFirst>0){
          if(valLast>=this.item.box_quantity){
            resto = parseInt(valLast)%this.item.box_quantity
            cocient = parseInt(Math.floor(valLast / this.item.box_quantity )) + parseInt(valFirst)
            this.item.quantity =  cocient +'F'+ resto
          }
        }
        else{
          if(valLast>=this.item.box_quantity){
            resto = parseInt(valLast)%this.item.box_quantity
            valFirst = Math.floor(valLast / this.item.box_quantity )
            this.item.quantity =  valFirst +'F'+ resto
          }
        }
      }
    },

  }
}
</script>
<style scoped>
.v-text-field >>> input {
  font-size: 12px;
}
*, ::before, ::after {
  background-repeat: no-repeat;
  box-sizing: unset;
}
</style>

